import { Pinia } from "pinia";
import { useCommonCodeStore } from "~/store/commonCodeStore";
import { useRouteStore } from "~/store/routeStore"
import { useTocStore } from "~/store/tocStore";
import { useUserStore } from "~/store/userStore";

export default defineNuxtRouteMiddleware(async(to, from) => {
  
  const app = useNuxtApp();


  // const routeStore = useRouteStore(app.$pinia as Pinia);
  // const userStore = useUserStore(app.$pinia as Pinia);
  // const tocStore = useTocStore(app.$pinia as Pinia);
  // const commonStore = useCommonCodeStore(app.$pinia as Pinia);

  // await commonStore.getInitAllCode();
  // await commonStore.getInitAllCodeMap();
  // const commonCodeStore = useCommonCodeStore(app.$pinia as Pinia);

  // const checkPathString = ['install', 'operate']
  // const checkPathEngSource = commonCodeStore.getCodeToArray('engSource').map(v => v.value.alias);
  // if(to.fullPath.includes('toc') && from.fullPath.includes('toc')){
    
    // tocStore.setRouteForm(from.fullPath, to.fullPath, checkPathString);

  // }
})